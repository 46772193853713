import { ref, readonly } from 'vue';
import { $track } from '@/plugins/analytics';

const state = ref({
    activeExperiment: null
});

const initExperiment = (name, variants) => {
    if (state.value.activeExperiment) return state.value.activeExperiment.currentVariant;
    const randomVariant = variants[Math.floor(Math.random() * variants.length)];
    state.value.activeExperiment = {
        name,
        variants,
        currentVariant: randomVariant
    };
    setExperiment(state.value.activeExperiment);

    return randomVariant;
};

const setVariant = (variant) => {
    if (!state.value.activeExperiment) return;
    if (!state.value.activeExperiment.variants.includes(variant)) return;
    state.value.activeExperiment.currentVariant = variant;
    setExperiment(state.value.activeExperiment);
};

const setExperiment = (experiment) => {
    state.value.activeExperiment = experiment;
    localStorage.setItem('coassemble:experiment', JSON.stringify(state.value.activeExperiment));
    $track.baseProperties = {
        ...$track.baseProperties,
        'experiment_name': state.value.activeExperiment.name,
        'experiment_variant': state.value.activeExperiment.currentVariant
    };
};

const storedExperiment = localStorage.getItem('coassemble:experiment');
if (storedExperiment) {
    state.value.activeExperiment = JSON.parse(storedExperiment);
    setExperiment(state.value.activeExperiment);
}

export default readonly({
    state,
    setVariant,
    initExperiment,
});
